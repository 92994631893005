<template>
  <div :style="!user_location_granted ? 'height: 100vh' : null" id="app">
    <Navbar />
    <router-view/>
    <Footer />
    <Addon />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import Addon from '@/components/Addon';
export default {
  computed: {
    ...mapGetters([
      'user_location_granted'
    ])
  },
  methods: {
    ...mapActions([
      'fetch_drink_categories'
    ])
  },
  components: {
    Navbar,
    Footer,
    Addon
  },
  mounted() {
    this.fetch_drink_categories();
    let specific_listing = document.createElement('script')
    specific_listing.setAttribute('src', '/assets/js/specific_listing.js')
    document.body.appendChild(specific_listing);
  }
}
</script>

<style>

</style>
