import Vue from 'vue';
import Vuex from 'vuex';
import { BASE_URL, MAP_API_KEY } from '@/main';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_token: localStorage.getItem("user_token") || "",
    user_data: localStorage.getItem("user_data") || "",
    restaurant_types: localStorage.getItem("restaurant_types") || "",
    all_menu: localStorage.getItem("all_menu") || "",
    all_order_items: localStorage.getItem("all_order_items") || "",
    all_orders: localStorage.getItem("all_orders") || "",
    latitude: localStorage.getItem("latitude") || "",
    longitude: localStorage.getItem("longitude") || "",
    location_granted: localStorage.getItem("location_granted") || "null",
    location_details: localStorage.getItem("location_details") || "",
    all_restaurants: localStorage.getItem("all_restaurants") || "",
    drinks_categories: localStorage.getItem("drinks_categories") || "",
    all_drinks: localStorage.getItem("all_drinks") || ""
  },
  getters: {
    auth_all_drinks: state => {
      if (state.all_drinks) {
        return JSON.parse(state.all_drinks);
      }
      return null
    },
    auth_drinks_categories: state => {
      if (state.drinks_categories) {
        return JSON.parse(state.drinks_categories).drinkcategories;
      }
      return null
    },
    auth_all_restaurants: state => {
      if (state.all_restaurants) {
        return JSON.parse(state.all_restaurants);
      }
      return null
    },
    user_state: (state, getters) => {
      if (getters.all_location_results) {
        let place;
        for (let index = 0; index < getters.all_location_results.length; index++) {
          console.log(getters.all_location_results[index].toLowerCase());
          if (getters.all_location_results[index].toLowerCase().includes('abuja') 
          || 
          getters.all_location_results[index].toLowerCase().includes('federal capital territory')
          ||
          getters.all_location_results[index].toLowerCase().includes('f.c.t')) {
            place = 'Abuja';
            console.log('Abuja');
            break;
          } else if (getters.all_location_results[index].toLowerCase().includes('enugu')) {
            place = 'Enugu';
            console.log('Enugu');
            break;
          } else if (getters.all_location_results[index].toLowerCase().includes('port harcourt') || getters.all_location_results[index].toLowerCase().includes('port-harcourt')) {
            place = 'Port Harcourt';
            console.log('PH');
            break;
          } else {
            console.log('Lagos');
            place = 'Lagos';
          }
        }
        // getters.all_location_results.forEach(location => {
        //   if (location.toLowerCase().includes('abuja') 
        //   || 
        //   location.toLowerCase().includes('federal capital territory')
        //   ||
        //   location.toLowerCase().includes('f.c.t')) {
        //     place = 'Abuja';
        //   } else if (location.toLowerCase().includes('enugu')) {
        //     place = 'Enugu';
        //   } else if (location.toLowerCase().includes('port harcourt') || location.toLowerCase().includes('port-harcourt')) {
        //     place = 'Port Harcourt';
        //   } else {
        //     place = 'Lagos';
        //   }
        // });
        return place;
      }
      return null;
    },
    all_location_results: state => {
      let results = [];
      if (state.location_details && JSON.parse(state.location_details).results) {
        results = JSON.parse(state.location_details).results.map((location) => {
          return location.formatted_address;
        });
      }
      return results;
    },
    user_location_granted: state => {
        return JSON.parse(state.location_granted);
    },
    current_location: state => {
      if (state.location_details && JSON.parse(state.location_details).results[0]) {
        return JSON.parse(state.location_details).results[0].formatted_address;
      }
      return 'Please allow your browser detect your location';
    },
    auth_all_orders: (state, getters) => {
      let orders = [];
      if (getters.auth_all_order_items && state.all_orders) {
        getters.auth_all_order_items.forEach(item => {
          JSON.parse(state.all_orders).forEach(order => {
            if (order.id == item.order_id) {
              orders.push(order);
            }
          })
        })
      }
      return orders;
    },
    auth_all_menu: state => {
      if (state.all_menu) {
        return JSON.parse(state.all_menu).allmenu;
      }
      return null;
    },
    auth_user_data: state => {
      if (state.user_data) {
        return JSON.parse(state.user_data);
      }
      return null;
    },
    auth_restaurant_types: state => {
      if (state.restaurant_types) {
        return JSON.parse(state.restaurant_types);
      }
      return null;
    },
    logged_in: state => {
        return !(!state.user_token || !state.user_data);
    },
  },
  mutations: {
    FETCH_ALL_DRINKS: (state, all_drinks) => {
        state.all_drinks = all_drinks;
    },
    FETCH_DRINK_CATEGORIES: (state, drinks_categories) => {
        state.drinks_categories = drinks_categories;
    },
    FETCH_ALL_RESTAURANTS: (state, all_restaurants) => {
        state.all_restaurants = all_restaurants;
    },
    SET_LOCATION_DETAILS: (state, location_details) => {
        state.location_details = location_details;
    },
    SET_LOCATION: (state, location_details) => {
      if (location_details[0] && location_details[1]) {
        state.location_granted = JSON.stringify(true);
        state.latitude = location_details[0];
        state.longitude = location_details[1];
      } else {
        state.location_granted = JSON.stringify(false);
      }
    },
    FETCH_ALL_ORDERS: (state, all_orders) => {
        state.all_orders = all_orders;
    },
    FETCH_ALL_ORDER_ITEMS: (state, all_order_items) => {
        state.all_order_items = all_order_items;
    },
    FETCH_MENU_CATEGORIES: (state, vendor_menu_categories) => {
        state.vendor_menu_categories = vendor_menu_categories;
    },
    FETCH_ALL_MENU: (state, all_menu) => {
        state.all_menu = all_menu;
    },
    FETCH_RESTAURANT_TYPES: (state, restaurant_types) => {
        state.restaurant_types = restaurant_types;
    },
    SUCCESSFUL_LOGIN: (state, user_info) => {
        state.user_token = user_info.success.token;
        state.user_data = user_info.user;
    },
    SUCCESSFUL_PROFILE_UPDATE: (state, user_data) => {
        state.user_data = user_data;
    },
    LOGOUT_USER: state => {
        state.user_data = "";
        state.user_token = "";
    },
  },
  actions: {
    fetch_all_drinks: context => {
      let all_drinks = [];
      context.getters.auth_drinks_categories.forEach(dcg => {
        Vue.axios
          .post(`${BASE_URL}fetchalldrinksbycategory`, {
            category_id: dcg.id
          })
          .then(response => {
              const RESPONSE = response.data;
              RESPONSE.drinks.forEach(drink => all_drinks.push(drink));
          })
          .catch(() => {
            //
          })
          .then(() => {
            localStorage.setItem("all_drinks", JSON.stringify(all_drinks)); 
            context.commit("FETCH_ALL_DRINKS", JSON.stringify(all_drinks));
          });
      })
    },
    fetch_drink_categories: context => {
      Vue.axios
        .post(`${BASE_URL}fetchdrinkcategories`)
        .then(response => {
            const RESPONSE = response.data;
            localStorage.setItem("drink_categories", JSON.stringify(RESPONSE)); 
            context.commit("FETCH_DRINK_CATEGORIES", JSON.stringify(RESPONSE));
        })
        .catch(() => {
          //
        })
        .then(() => {
          if(context.getters.auth_drinks_categories.length) {
            context.dispatch('fetch_all_drinks');
          }
        });
    },
    fetch_all_restaurants: context => {
      Vue.axios
        .post(`${BASE_URL}fetchallrestaurants`, { 
          state: context.getters.user_state 
        })
        .then(response => {
            const RESPONSE = response.data;
            localStorage.setItem("all_restaurants", JSON.stringify(RESPONSE)); 
            context.commit("FETCH_ALL_RESTAURANTS", JSON.stringify(RESPONSE));
        })
        .catch(() => {
          //
        })
        .then(() => {
          //
        });
    },
    get_location_details: context => {
      Vue.axios
        .get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${parseFloat(context.state.latitude)},${parseFloat(context.state.longitude)}&key=${MAP_API_KEY}`)
        .then(response => {
            const RESPONSE = response.data;
            localStorage.setItem("location_details", JSON.stringify(RESPONSE)); 
            context.commit("SET_LOCATION_DETAILS", JSON.stringify(RESPONSE));
        })
        .catch(() => {
          //
        })
        .then(() => {
            context.dispatch("fetch_all_restaurants");
        });
    },
    get_location: context => {
      let success = function (position) {
        localStorage.setItem("latitude", JSON.stringify(position.coords.latitude));
        localStorage.setItem("longitude", JSON.stringify(position.coords.longitude));
        localStorage.setItem("location_granted", JSON.stringify(true));
        context.commit("SET_LOCATION", [
          JSON.stringify(position.coords.latitude), 
          JSON.stringify(position.coords.longitude)
        ]),
        context.dispatch("get_location_details");
      };
      let error = function () {
        localStorage.setItem("location_granted", JSON.stringify(false))
        context.commit("SET_LOCATION", "");
      };
      navigator.geolocation.getCurrentPosition(success, error);
    },
    fetch_all_orders: context => {
      Vue.axios
        .get(`${BASE_URL}fetchallorders`)
        .then(response => {
            const RESPONSE = response.data;
            localStorage.setItem("all_orders", JSON.stringify(RESPONSE));
            context.commit("FETCH_ALL_ORDERS", JSON.stringify(RESPONSE));
        })
        .catch(() => {
          //
        })
        .then(() => {
          //
        });
    },
    fetch_all_order_items: context => {
      Vue.axios
        .get(`${BASE_URL}fetchallorderitems`)
        .then(response => {
            const RESPONSE = response.data;
            localStorage.setItem("all_order_items", JSON.stringify(RESPONSE));
            context.commit("FETCH_ALL_ORDER_ITEMS", JSON.stringify(RESPONSE));
        })
        .catch(() => {
          //
        })
        .then(() => {
          //
        });
    },
    fetch_all_menu: context => {
      Vue.axios
        .post(`${BASE_URL}fetchmenu`)
        .then(response => {
            const RESPONSE = response.data;
            localStorage.setItem("all_menu", JSON.stringify(RESPONSE));
            context.commit("FETCH_ALL_MENU", JSON.stringify(RESPONSE));
        })
        .catch(() => {
          //
        })
        .then(() => {
          //
        });
    },
    fetch_vendor_menu_categories: context => {
      Vue.axios
        .post(`${BASE_URL}fetchallcategories`)
        .then(response => {
            const RESPONSE = response.data;
            localStorage.setItem("vendor_menu_categories", JSON.stringify(RESPONSE));
            context.commit("FETCH_MENU_CATEGORIES", JSON.stringify(RESPONSE));
        })
        .catch(() => {
          //
        })
        .then(() => {
          //
        });
    },
    fetch_restaurant_types: context => {
      Vue.axios
        .get(`${BASE_URL}gettypes`)
        .then(response => {
            const RESPONSE = response.data;
            localStorage.setItem("restaurant_types", JSON.stringify(RESPONSE));
            context.commit("FETCH_RESTAURANT_TYPES", JSON.stringify(RESPONSE));
        })
        .catch(() => {
          //
        })
        .then(() => {
          //
        });
    },
    store_login: (context, user_info) => {
      localStorage.setItem("user_data", JSON.stringify(user_info.user));
      localStorage.setItem("user_token", JSON.stringify(user_info.success.token));
      const USER = { 
        success: { 
          token: JSON.stringify(user_info.success.token) 
        }, 
        user: JSON.stringify(user_info.user) 
      };
      context.commit("SUCCESSFUL_LOGIN", USER);
    },
    store_profile_update: (context, user_data) => {
      localStorage.setItem("user_data", JSON.stringify(user_data));
      context.commit("SUCCESSFUL_PROFILE_UPDATE", JSON.stringify(user_data));
    },
    logout: context => {
      localStorage.removeItem("user_token")
      localStorage.removeItem("user_data")
      context.commit("LOGOUT_USER");
    },
  },
  modules: {
  }
})
