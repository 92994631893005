import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import Restaurant from '../views/Restaurant';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/restaurant/:id-:name',
    name: 'Restaurant',
    component: Restaurant
  }
]

const router = new VueRouter({
  scrollBehavior: function() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes
})

export default router
