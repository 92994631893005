<template>
    <div v-if="route_details.status != 'ZERO_RESULTS' && details.opening_time" 
    class="col-xl-4 col-lg-6 col-md-6 col-sm-6 isotope-item delivery">
        <div class="strip">
            <figure>
                <span v-if="is_open" class="ribbon off">Open</span>
                <span v-else style="background-color: #dc3545" class="ribbon off">Closed</span>
                <img :src="details.image_url ? assets_url + details.image_url : '/assets/img/lazy-placeholder.jpg'" :data-src="details.image_url ? assets_url + details.image_url : '/assets/img/lazy-placeholder.jpg'" class="img-fluid lazy" alt="">
                <router-link :to="{ name: 'Restaurant', params: { id: details.id, name: details.restaurant_name.toLowerCase().replaceAll(' ', '-') } }" class="strip_info">
                    <small>{{ route_details.routes[0].legs[0].duration.text }}</small>
                    <div class="item_title">
                        <h3>{{ details.restaurant_name }}</h3>
                        <small>{{ details.address }} ({{ route_details.routes[0].legs[0].distance.text }})</small>
                    </div>
                </router-link>
            </figure>
            <ul>
                <li>
                    <span class="take yes">
                        {{ 
                            details.opening_time > 12 ?
                            ((details.opening_time.length == 1 ? "0" + details.opening_time : parseInt(details.opening_time) - 12)
                            + ":00PM") 
                            :
                             ((details.opening_time.length == 1 ? "0" + details.opening_time : details.opening_time)
                            + ":00AM")

                            + " - " +

                            (details.closing_time > 12 ?
                            ((details.closing_time.length == 1 ? "0" + details.closing_time : parseInt(details.closing_time) - 12)
                            + ":00PM") 
                            :
                             ((details.closing_time.length == 1 ? "0" + details.closing_time : details.closing_time)
                            + ":00AM"))
                        }}
                    </span>
                </li>
                <li>
                    <div class="score">
                        <strong>{{ details.restaurant_rating }}</strong>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- /strip grid -->

    <div v-else 
    class="col-xl-4 col-lg-6 col-md-6 col-sm-6 isotope-item delivery">
        <div class="strip">
            <figure>
                <span v-if="is_open" class="ribbon off">Open</span>
                <span v-else style="background-color: #dc3545" class="ribbon off">Closed</span>
                <img 
                :src="details.image_url ? assets_url + details.image_url : '/assets/img/lazy-placeholder.jpg'" 
                :data-src="details.image_url ? assets_url + details.image_url : '/assets/img/lazy-placeholder.jpg'" 
                class="img-fluid lazy" 
                alt="" />
                <router-link :to="{ name: 'Restaurant', params: { id: details.id, name: details.restaurant_name.toLowerCase().replaceAll(' ', '-') } }" class="strip_info">
                    <small>Can't get distance</small>
                    <div class="item_title">
                        <h3>{{ details.restaurant_name }}</h3>
                        <small>{{ details.address }} (Can't get distance)</small>
                    </div>
                </router-link>
            </figure>
            <ul>
                <li>
                    <span class="take yes">
                        {{ 
                            (details.opening_time.length == 1 ? "0" + details.opening_time : details.opening_time)
                            + ":00 - " +
                            (details.closing_time.length == 1 ? "0" + details.closing_time : details.closing_time)
                            + ":00"
                        }}
                    </span>
                </li>
                <li>
                    <div class="score">
                        <strong>{{ details.restaurant_rating }}</strong>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- /strip grid -->
</template>

<script>
import { mapState } from 'vuex';
import { ASSETS_URL } from '@/main';
export default {
    data() {
        return {
            route_details: { 
                routes: [{ 
                    legs: [{ 
                        duration: { 
                            text: "...loading" 
                        }, 
                        distance: { 
                            text: "...loading" 
                        } 
                    }] 
                }] 
            }
        }
    },
    computed: {
        ...mapState([
            "latitude",
            "longitude"
        ]),
        assets_url() {
            return ASSETS_URL;
        },
        is_open() {
            if (this.details.opening_time && this.details.closing_time) {
                var t = 1 == this.details.opening_time.length ? "0" + this.details.opening_time : this.details.opening_time,
                    a = 1 == this.details.closing_time.length ? "0" + this.details.closing_time : this.details.closing_time,
                    e = new Date().toGMTString().substr(0, 17) + t + ":00:00 GMT+1",
                    s = new Date().toGMTString().substr(0, 17) + a + ":00:00 GMT+1";
                return (e = new Date(e).getTime()), 
                (s = new Date(s).getTime()), 
                new Date().getTime() >= e && new Date().getTime() <= s;
            }
            return false;
        },
    },
    props: {
        details: { 
            type: Object, 
            required: true 
        }, 
        timeout: { 
            type: Number, 
            required: true 
        }
    },
    mounted() {
        let _this = this;
        const DS = new window.google.maps.DirectionsService();
        setTimeout(() => {
            DS.route({ 
                origin: _this.latitude + "," + _this.longitude, 
                destination: _this.details.latitude + "," + _this.details.longitude, 
                travelMode: "DRIVING" 
            }, (details, status) => {
                if (status == "OK") {
                    _this.route_details = details;
                }
            });
        }, _this.timeout);
    }
}
</script>

<style>

</style>