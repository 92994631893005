<template>
  <main>
    <div class="page_header element_to_stick">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 col-lg-7 col-md-7 d-none d-md-block">
                    <h1>{{ current_location || 'Please allow your browser detect your location' }}</h1>
                    <a href="#" @click="get_location2()">Detect current location</a>
                </div>
                <div class="col-xl-4 col-lg-5 col-md-5 d-md-block pt-xs-2 pt-0">
                    <div class="search_bar_list">
                        <input v-model="search" type="text" class="form-control" placeholder="Search restaurants...">
                        <button type="submit"><i class="icon_search"></i></button>
                    </div>
                </div>
            </div>
            <!-- /row -->
        </div>
    </div>
    <!-- /page_header -->
    <div class="filters_full clearfix add_bottom_15">
        <div class="container">
            <div class="type_delivery">
          <ul class="clearfix">
            <li>
                  <label class="container_radio">All
                      <input @click="restaurant_type = 'All'" type="radio" name="type_d" value="all" id="all" checked data-filter="*" class="selected">
                      <span class="checkmark"></span>
                  </label>
              </li>
              <li v-for="type in auth_restaurant_types" :key="type.id">
                  <label class="container_radio">{{ type.type_name }}
                      <input @click="restaurant_type = type.type_name" type="radio" name="type_d" :value="type.type_name" id="delivery" data-filter=".delivery">
                      <span class="checkmark"></span>
                  </label>
              </li>
          </ul>
      </div>
      <!-- /type_delivery -->
        </div>
    </div>
    <!-- /filters_full -->

    <div class="collapse filters_2" id="collapseFilters">
        <div class="container margin_30_20">
            <div class="row">
                <div class="col-md-4">
                    <div class="filter_type">
                        <h6>Categories</h6>
                        <ul>
                            <li>
                                <label class="container_check">Pizza - Italian <small>12</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Japanese - Sushi <small>24</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Burghers <small>23</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Vegetarian <small>11</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="filter_type">
                        <h6>Rating</h6>
                        <ul>
                            <li>
                                <label class="container_check">Superb 9+ <small>06</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Very Good 8+ <small>12</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Good 7+ <small>17</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="container_check">Pleasant 6+ <small>43</small>
                                    <input type="checkbox">
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="filter_type">
                        <h6>Distance</h6>
                        <div class="distance"> Radius around selected destination <span></span> km</div>
                        <div class="mb-3
                        "><input type="range" min="10" max="100" step="10" value="30" data-orientation="horizontal"></div>
                    </div>
                </div>
            </div>
            <!-- /row -->
        </div>
    </div>
    <!-- /filters -->
    <div class="container margin_30_20">

        <div class="promo mb_30">
            <h3>With you all the way</h3>
            <p>Find us at your favourite smartphone app stores. Whenever! However!</p>
            <i class="icon-food_icon_delivery"></i>
        </div>
        <!-- /promo -->
        <div v-if="user_location_granted" class="row isotope-wrapper">
          <div class="col-12">
            <h2 class="title_small">All Restaurants</h2>
          </div>
          <!-- <template v-if="search">
            <RestCard 
            v-for="(rs, index) in searched_restaurants"
            :key="rs.id"
            :details="rs"
            :timeout="get_timeout(index)"
            />
          </template> -->
          <template v-for="(rs, index) in filtered_restaurants">
              <keep-alive :key="rs.id">
                <RestCard 
                v-if="rs.address.toLowerCase().indexOf(search.toLowerCase()) != -1
                ||
                rs.owner_name.toLowerCase().indexOf(search.toLowerCase()) != -1
                ||
                rs.restaurant_name.toLowerCase().indexOf(search.toLowerCase()) != -1"
                :details="rs"
                :timeout="get_timeout(index)"
                />
              </keep-alive>
          </template>
        </div>
        <!-- /strip row -->
    </div>
    <!-- /container -->
</main>
<!-- /main -->
</template>

<script>
import RestCard from '@/components/RestCard';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Home',
  data() {
      return {
          search: '',
          restaurant_type: 'All'
      }
  },
  watch: {
    user_location_granted(new_value) {
        if (new_value == false) {
            this.$swal.fire(
                "😟", 
                "You have to enable location detection to get deliveries to you.", 
                "warning"
            );
        }
    },
  },
  computed: {
      ...mapGetters([
          "auth_user_data", 
          "auth_restaurant_types", 
          "auth_all_orders", 
          "current_location", 
          "user_location_granted", 
          "auth_all_restaurants"
      ]),
    //   searched_restaurants() {
    //       let _this = this, searched = [];
    //       searched = _this.filtered_restaurants.filter(rs => {
    //           let truth = rs.address.toLowerCase().indexOf(_this.search.toLowerCase()) != -1 
    //           || rs.owner_name.toLowerCase().indexOf(_this.search.toLowerCase()) != -1 
    //           || rs.restaurant_name.toLowerCase().indexOf(_this.search.toLowerCase()) != -1
    //           return truth;
    //       });
    //       return searched;
    //   },
      filtered_restaurants: function () {
          let _this = this;
          if (_this.restaurant_type == 'All') {
              if (_this.auth_all_restaurants) {
                  return _this.auth_all_restaurants.filter(rs => {
                    return rs.latitude && rs.longitude;
                })
              }
              return [];
          } else {
              return _this.auth_all_restaurants.filter(rs => {
                  return rs.type_id == _this.rs_type_id && (rs.latitude && rs.longitude);
              })
          }
        },
        rs_type_id() {
            let _this = this;
            if (_this.auth_restaurant_types && _this.restaurant_type != 'All') {
                return _this.auth_restaurant_types.filter(type => {
                    return type.type_name == _this.restaurant_type;
                })[0].id;
            }
            return null;
        },
  },
  methods: {
      ...mapActions([
          "fetch_restaurant_types", 
          "fetch_all_menu", 
          "fetch_all_order_items", 
          "fetch_all_orders", 
          "get_location",
          "fetch_vendor_menu_categories"
      ]),
      get_timeout(index) {
            let timeout = 5000 * Math.floor(index / 5);
            return timeout;
        },
        get_location2 () {
            let _this = this;
            if (!_this.user_location_granted) {
                _this.$swal.fire(
                    "😟", 
                    "Geolocation is not enabled. Please enable to use this feature.", 
                    "warning"
                    ).then(() => {
                        _this.get_location();
                    })
            }
            _this.get_location();
        },
  },
  components: {
      RestCard
  },
  mounted() {
        document.title = "Chops - Think Food Think Chops";
        this.get_location();
        this.auth_restaurant_types || this.fetch_restaurant_types();
        this.fetch_all_order_items();
        this.fetch_all_orders();
        this.fetch_all_menu();
        this.fetch_vendor_menu_categories();
    }
}
</script>
<style>
@media only screen and (max-width: 768px) {
  .pt-xs-2 {
    padding-top: .5rem;
  }
}
</style>
