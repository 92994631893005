<template>
  <main>
    <div class="hero_in detail_page background-image" :style="'background-image: url(' + (assets_url + restaurant.image_url) + ');'">
      <div class="wrapper opacity-mask">
        <div class="container">
          <div class="main_info">
            <div class="row">
              <div class="col-xl-4 col-lg-5 col-md-6">
                <div class="head">
                  <div class="score">
                    <span>Superb<em>1 Review</em></span>
                    <strong>{{ restaurant.restaurant_rating }}</strong>
                  </div>
                </div>
                <h1>{{ restaurant.restaurant_name }}</h1>
                <template v-if="route_details.routes[0].legs[0].duration.text == '...loading'">
                  {{ restaurant.address }} - ({{ route_details.routes[0].legs[0].duration.text }})
                </template>
                <template v-else>
                  {{ restaurant.address }} - ({{ route_details.routes[0].legs[0].duration.text }} to your location)
                </template>
              </div>
            </div>
            <!-- /row -->
          </div>
          <!-- /main_info -->
        </div>
      </div>
    </div>
    <!--/hero_in-->

    <nav class="secondary_nav sticky_horizontal">
        <div class="container">
            <ul id="secondary_nav">
                <li
                class="mb-md-3" 
                v-for="category in restaurant_categories" 
                :key="category">
                  <a class="list-group-item list-group-item-action" :href="'#'+category">
                    {{ category }}
                  </a>
                </li>
                <li class="d-none"><a class="list-group-item list-group-item-action" href="#">&nbsp;</a></li>
            </ul>
        </div>
        <span></span>
    </nav>
    <!-- /secondary_nav -->

    <div class="bg_gray">
        <div 
        class="container margin_detail" 
        :class="view_basket ? 'pt-3' : null">
            <div v-if="view_basket" class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="box_order">
                        <div class="head">
                            <h3>Order Summary</h3>
                            <a href="#" class="close_panel_mobile"><i class="icon_close"></i></a>
                        </div>
                        <!-- /head -->
                        <div class="main">
                            <ul v-if="cart.length >= 1" class="clearfix">
                                <li 
                                class="row pl-3"
                                v-for="cart_item in cart"
                                :key="cart_item.id"
                                >
                                  <a 
                                  @click.prevent="remove_from_cart(cart_item)"
                                  class="col-9"
                                  href="#">
                                    {{ cart_item.number_to_order }}x {{ cart_item.food_name }}
                                  </a>
                                  <span class="col-3 pl-0 text-right">
                                    &#8358;{{ (cart_item.number_to_order * cart_item.price) | number_with_commas }}
                                  </span>
                                </li>
                            </ul>
                            <ul v-else class="clearfix">
                                <li 
                                class="row pl-0"
                                >
                                  <h5 class="col-9">
                                    NO ITEM IN CART
                                  </h5>
                                </li>
                            </ul>
                            <ul class="clearfix">
                                <li>Subtotal<span>&#8358;{{ total_amount | number_with_commas }}</span></li>
                                <li>VAT<span>&#8358;{{ vat | number_with_commas }}</span></li>
                                <li>Delivery fee<span>&#8358;{{ delivery_fee | number_with_commas }}</span></li>
                                <li class="total">Total<span>&#8358;{{ (total_amount + delivery_fee + vat) | number_with_commas }}</span></li>
                            </ul>
                            <div v-if="logged_in" class="row opt-order py-4" style="border-top: 1px solid #ededed;">
                              <div class="col-md-12 form-group">
                                  <label class="d-block">
                                    Address
                                    <span 
                                    @click="get_location2()"
                                    class="mt-1 edit-location fal fa-edit float-right" 
                                    style="cursor: pointer;">
                                    </span>
                                  </label>
                                  <textarea 
                                  class="form-control"
                                  :disabled="true" 
                                  v-model="current_location"
                                  >
                                  </textarea>
                              </div>
                              <div class="col-md-12 form-group">
                                  <label>Phone Number</label>
                                  <input
                                  :disabled="true" 
                                  class="form-control" 
                                  :value="auth_user_data.phone" />
                              </div>
                            </div>
                            <div class="row opt_order">
                                <div class="col-6">
                                    <label class="container_radio">Pay On Delivery
                                        <input v-model="payment" type="radio" value="0" name="opt_order" checked>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-6">
                                    <label class="container_radio">Pay Now
                                        <input v-model="payment" type="radio" value="1" name="opt_order">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="btn_1_mobile">
                                <a
                                @click="cart.length >= 1 ? check_user() : null"
                                :style="cart.length < 1 ? 'opacity: 0.5; cursor: no-drop' : null" 
                                class="btn_1 gradient full-width mb_5">
                                  <span>
                                      {{ order_text }}
                                      <i class="fad fa-spinner fa-spin" :style="!order_load ? 'display: none;' : null"></i>
                                  </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- /box_order -->
                    <div class="btn_reserve_fixed">
                      <a @click="view_basket = !view_basket" class="btn_1 gradient full-width">
                        {{ view_basket ? 'View Menu' : 'View Cart' }}
                      </a>
                    </div>
                </div>
            </div>
            <!-- /row -->
            <div v-else class="row">
                <div class="col-lg-8 list_menu">
                    <section 
                    v-for="category in restaurant_categories" 
                    :key="category" 
                    :id="category">
                        <h4>{{ category }}</h4>
                        <div class="row">
                          <template
                          v-for="menu in restaurant_menu" >
                            <div 
                            v-if="menu.category_name == category"
                            :key="menu.id"
                            class="col-md-6">
                                <a 
                                style="cursor: pointer;"
                                @click="setup_quantity(menu)" 
                                class="menu_item modal_dialog" 
                                data-toggle="modal" 
                                data-target="#quantityModal">
                                    <figure>
                                      <img src="/assets/img/menu-thumb-placeholder.jpg" data-src="/assets/img/menu-thumb-placeholder.jpg" alt="thumb" class="lazy">
                                    </figure>
                                    <h3>
                                      {{ menu.food_name }}
                                    </h3>
                                    <p v-if="menu.description">
                                      {{ menu.description }}
                                    </p>
                                    <p v-else>
                                      <i>
                                        No description
                                      </i>
                                    </p>
                                    <strong>&#8358;{{ menu.price | number_with_commas }}</strong>
                                </a>
                            </div>
                          </template>
                        </div>
                        <!-- /row -->
                    </section>
                </div>
                <!-- /col -->
                <div class="col-lg-4 col-md-4 col-12" id="sidebar_fixed">
                    <div class="box_order">
                        <div class="head">
                            <h3>Order Summary</h3>
                            <a href="#" class="close_panel_mobile"><i class="icon_close"></i></a>
                        </div>
                        <!-- /head -->
                        <div class="main">
                            <ul v-if="cart.length >= 1" class="clearfix">
                                <li 
                                class="row pl-3"
                                v-for="cart_item in cart"
                                :key="cart_item.id"
                                >
                                  <a 
                                  @click.prevent="remove_from_cart(cart_item)"
                                  class="col-9"
                                  href="#">
                                    {{ cart_item.number_to_order }}x {{ cart_item.food_name }}
                                  </a>
                                  <span class="col-3 pl-0 text-right">
                                    &#8358;{{ (cart_item.number_to_order * cart_item.price) | number_with_commas }}
                                  </span>
                                </li>
                            </ul>
                            <ul v-else class="clearfix">
                                <li 
                                class="row pl-0"
                                >
                                  <h5 class="col-9">
                                    NO ITEM IN CART
                                  </h5>
                                </li>
                            </ul>
                            <ul class="clearfix">
                                <li>Subtotal<span>&#8358;{{ total_amount | number_with_commas }}</span></li>
                                <li>VAT<span>&#8358;{{ vat | number_with_commas }}</span></li>
                                <li>Delivery fee<span>&#8358;{{ delivery_fee | number_with_commas }}</span></li>
                                <li class="total">Total<span>&#8358;{{ (total_amount + delivery_fee + vat) | number_with_commas }}</span></li>
                            </ul>
                            <div v-if="logged_in" class="row opt-order py-4" style="border-top: 1px solid #ededed;">
                              <div class="col-md-12 form-group">
                                  <label class="d-block">
                                    Address
                                    <span
                                    @click="get_location2()"
                                    class="mt-1 edit-location fal fa-edit float-right" 
                                    style="cursor: pointer;">
                                    </span>
                                  </label>
                                  <textarea 
                                  class="form-control"
                                  :disabled="true" 
                                  v-model="current_location"
                                  >
                                  </textarea>
                              </div>
                              <div class="col-md-12 form-group">
                                  <label>Phone Number</label>
                                  <input
                                  :disabled="true" 
                                  class="form-control" 
                                  :value="auth_user_data.phone" />
                              </div>
                            </div>
                            <div class="row opt_order">
                                <div class="col-6">
                                    <label class="container_radio">Pay On Delivery
                                        <input v-model="payment" type="radio" value="0" name="opt_order" checked>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-6">
                                    <label class="container_radio">Pay Now
                                        <input v-model="payment" type="radio" value="1" name="opt_order">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="btn_1_mobile">
                                <a
                                @click="cart.length >= 1 ? check_user() : null"
                                :style="cart.length < 1 ? 'opacity: 0.5; cursor: no-drop' : null" 
                                class="btn_1 gradient full-width mb_5">
                                  <span>
                                      {{ order_text }}
                                      <i class="fad fa-spinner fa-spin" :style="!order_load ? 'display: none;' : null"></i>
                                  </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- /box_order -->
                    <div class="btn_reserve_fixed">
                      <a @click="view_basket = !view_basket" class="btn_1 gradient full-width">
                        {{ view_basket ? 'View Menu' : 'View Cart' }}
                      </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- /container -->
    </div>
    <!-- /bg_gray -->


      <!-- Modal -->
      <div class="modal fade" id="quantityModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ current_menu.food_name }}</h5>
              <button ref="close_add_to_cart_modal" style="outline: none !important;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div 
            class="modal-body" 
            style="border-bottom: 1px solid #dee2e6;">
              <h5>Quantity</h5>
              <div class="numbers-row">
                  <input type="text" :value="quantity" id="qty_1" class="qty2 form-control" name="quantity">
                  <div @click="quantity += 1" class="inc button_inc">
                    <i class="fal fa-plus"></i>
                  </div>
                  <div @click="quantity != 1 ? quantity -= 1 : null" class="dec button_inc">
                    <i class="fal fa-minus"></i>
                  </div>
              </div>
            </div>
            <div class="modal-footer text-center row" style="display: block; border-top: none;">
              <button 
              type="button" 
              data-dismiss="modal" 
              class="btn btn-secondary col-md-4" 
              style="width: 30%; outline: none !important;">
                Cancel
              </button>
              <a 
              @click="add_to_cart()"
              class="btn_1 gradient mb_5" 
              style="width: 60%;">
                {{ cart_button }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="login_button" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div v-if="view == 'login'" class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Login</h5>
              <button @click="view == 'forgotpassword' ? view = 'login' : null" ref="close_auth" style="outline: none !important;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div 
            class="modal-body" 
            style="border-bottom: 1px solid #dee2e6;">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input v-model="form_data.email" type="text" placeholder="Enter email" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Password</label>
                    <input v-model="form_data.password" type="password" placeholder="Enter password" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <small class="float-left">
                    <a @click="view = 'register'" class="auth d-none d-md-block" style="cursor: pointer;">
                      Don't have an account? Register
                    </a>
                    <a @click="view = 'register'" class="auth d-block d-md-none" style="cursor: pointer;">
                      Tap to Register
                    </a>
                  </small>
                  <small class="float-right">
                    <a @click="view = 'forgotpassword'" class="auth" style="cursor: pointer;">
                      Forgot password
                  </a>
                  </small>
                </div>
              </div>
            </div>
            <div class="modal-footer text-center row" style="display: block; border-top: none;">
              <button 
              type="button" 
              data-dismiss="modal" 
              class="btn btn-secondary col-md-4" 
              style="width: 30%; outline: none !important;">
                Cancel
              </button>
              <button @click.prevent="login()" 
              class="btn_1 gradient mb_5"
              style="width: 60%;">
                  <span>
                      {{ login_text }}
                      <i class="fad fa-spinner fa-spin" :style="!login_load ? 'display: none;' : ''"></i>
                  </span>
              </button>
            </div>
          </div>
          <div v-else-if="view == 'register'" class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Register</h5>
              <button ref="close_auth" style="outline: none !important;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div 
            class="modal-body" 
            style="border-bottom: 1px solid #dee2e6;">
               <div class="row">
                 <div class="col-md-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input v-model="reg_form_data.name" type="text" placeholder="Enter your name" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Phone</label>
                    <input v-model="reg_form_data.phone" type="text" placeholder="Enter your phone number" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input v-model="reg_form_data.email" type="text" placeholder="Enter email" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Password</label>
                    <input v-model="reg_form_data.password" type="password" placeholder="Enter password" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Confirm Password</label>
                    <input v-model="reg_form_data.confirm_pass" type="password" placeholder="Confirm password" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12 text-right">
                  <small>
                    <a @click="view = 'login'" class="auth" style="cursor: pointer;">
                      Already have an account? Login
                  </a>
                  </small>
                </div>
              </div>
            </div>
            <div class="modal-footer text-center row" style="display: block; border-top: none;">
              <button 
              type="button" 
              data-dismiss="modal" 
              class="btn btn-secondary col-md-4" 
              style="width: 30%; outline: none !important;">
                Cancel
              </button>
              <button @click.prevent="register()" 
              class="btn_1 gradient mb_5"
              style="width: 60%;">
                  <span>
                      {{ reg_text }}
                      <i class="fad fa-spinner fa-spin" :style="!reg_load ? 'display: none;' : ''"></i>
                  </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <i 
      class="fal fa-plus"
      style="font-size: 0.1%; opacity: 0;"></i>
      <i class="fal fa-plus"
      style="font-size: 0.1%; opacity: 0;"></i>
      <a 
      ref="login_button"
      style="font-size: 0.1%; opacity: 0;"
      data-toggle="modal" 
      data-target="#login_button">
      &nbsp;
      </a>

  </main>
  <!-- /main -->
</template>

<script>
import { BASE_URL, ASSETS_URL, PAYSTACK_KEY } from '@/main';
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  data () {
      return {
          view_basket: false,
          payment: "0",
          quantity: 1,
          current_menu: {},
          cart: [],
          route_details: { 
            routes: [{ 
              legs: [{ 
                duration: { 
                  text: "...loading" 
                }, 
                distance: { 
                  text: "...loading" 
                } 
              }] 
            }] 
          },
          form_data: { 
            email: "", 
            password: "" 
          },
          reg_form_data: { 
            name: "", 
            email: "", 
            phone: "", 
            password: "", 
            confirm_pass: ""
          },
          login_text: "Login Now!",
          login_load: false,
          reg_text: "Sign Up",
          reg_load: false,
          order_text: "Order Now",
          order_load: false,
          view: "login",
          cart_button: 'Add to cart'
      }
  },
  computed: {
    ...mapGetters([
      "auth_restaurant_types", 
      "auth_all_menu", 
      "auth_all_restaurants", 
      "logged_in", 
      "auth_user_data", 
      "current_location", 
      "user_location_granted"
    ]),
    ...mapState([
      "latitude",
      "longitude"
    ]),
    order_food() {
        let _this = this;
        let the_order_food = [];
        if (_this.cart.length >= 1) {
          return _this.cart.map(item => {
            return {
              restId: parseInt(item.restaurant_id),
              menuId: parseInt(item.id),
              foodName: item.food_name,
              foodPrice: parseInt(item.price),
              restName: item.restaurant_name,
              restAddress: _this.restaurant.address,
              foodDescription: item.description,
              orderCount: item.number_to_order
            }
          })
        }
        return the_order_food;
    },
    distance_to_location() {
        let _this = this;
        if (_this.route_details && _this.route_details.routes) {
          return parseFloat(_this.route_details.routes[0].legs[0].distance.text.replace(" km", ""))
        }
        return "";
    },
    vat() {
        let _this = this;
        if (_this.total_amount) {
            let vat = 0.075 * _this.total_amount;
            return vat;
        }
        return 0;
    },
    delivery_fee() {
        let _this = this;
        if (_this.distance_to_location) {
            if (_this.distance_to_location <= 5) {
              return 500;
            }
            let fee = (100 * Math.floor(_this.distance_to_location - 5)) + 500;
            return fee;
        }
        return 2000;
    },
    total_amount() {
        let amount = 0, _this = this;
        _this.cart.forEach(item => {
          amount += (parseInt(item.price) * item.number_to_order)
        });
        return amount;
    },
    assets_url() {
        return ASSETS_URL;
    },
    restaurant_categories() {
        let _this = this, categories = [];
        if (_this.restaurant_menu) {
            _this.restaurant_menu.forEach(menu => {
              if (categories.indexOf(menu.category_name) == -1) {
                categories.push(menu.category_name)
              }
            })
        }
        return categories;
    },
    restaurant() {
        let _this = this;
        if (_this.auth_all_restaurants) {
          return _this.auth_all_restaurants.filter(rs => {
            return rs.id == _this.$route.params.id
          })[0]
        }
        return {};
    },
    restaurant_menu() {
        let _this = this;
        if (_this.auth_all_menu) {
          return _this.auth_all_menu.filter(menu => {
            return menu.restaurant_id == _this.$route.params.id
          })
        }
        return [];
    }
  },
  methods: {
    ...mapActions([
      "store_login", 
      "get_location",
      "fetch_all_menu"
    ]),
    setup_quantity(menu) {
      let _this = this, is_found = false;
      _this.current_menu = menu;
      _this.cart.forEach(item => {
        item.id == _this.current_menu.id ? is_found = true : null
      })
      if (is_found) {
        _this.quantity = _this.cart.filter(cart_item => {
          return cart_item.id == menu.id
        })[0].number_to_order;
        _this.cart_button = 'Update cart';
      } else {
        _this.quantity = 1;
        _this.cart_button = 'Add to cart';
      }
    },
    get_location2() {
        let _this = this;
        if (!_this.user_location_granted) {
          _this.$swal.fire(
            "😟", 
            "Geolocation is not enabled. Please enable to use this feature.", 
            "warning"
            ).then(() => {
              _this.get_location();
          })
        }
        _this.get_location();
    },
    check_user() {
        let _this = this;
        if (_this.logged_in) {
          if (_this.payment == "0") {
            if (!_this.order_load) {
              _this.order_text = "";
              _this.order_load = true;
              _this.axios
                .post(`${BASE_URL}adduserorder`, {
                    userId: _this.auth_user_data.id,
                    userEmail: _this.auth_user_data.email,
                    phone: _this.auth_user_data.phone,
                    deliveryFee: _this.delivery_fee,
                    foodFee: _this.total_amount,
                    payment_status: _this.payment,
                    orderfood: _this.order_food,
                    deliveryAddress: _this.current_location,
                })
                .then(() => {
                    _this.$swal.fire(
                      "Order Received", 
                      "Your order has been sent. You'll be contacted soon.", 
                      "success").then(() => {
                        _this.$router.push({ name: "Home" });
                    });
                })
                .catch(() => {
                    _this.$swal.fire(
                      "Oops", 
                      "There is a problem with your order. Check and try again.", 
                      "error"
                    );
                })
                .then(() => {
                    _this.order_text = "Order Now";
                    _this.order_load = false;
                });
            }
          } else {
            let letters = [
              "a",
              "b",
              "c",
              "d",
              "e",
              "f",
              "g",
              "h",
              "i",
              "j",
              "k",
              "l",
              "m",
              "n",
              "o",
              "p",
              "q",
              "r",
              "s",
              "t",
              "u",
              "v",
              "w",
              "x",
              "y",
              "z",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9"
            ];
            let ref = "";
            for (let i = 0; i <= 10; i++) {
              if (i === 5) {
                ref += new Date().getTime();
                continue;
              }
              // ref += Math.floor(letters[Math.random() * letters.length])
              ref += letters[
                Math.floor(Math.random() * letters.length)
              ].toUpperCase();
            }
            let handler = window.PaystackPop.setup({
              key: PAYSTACK_KEY,
              email: _this.auth_user_data.email,
              amount: 100 * (_this.total_amount + _this.delivery_fee + _this.vat),
              ref,
              onClose: function() {
                  _this.$swal.fire(
                    "Uh oh", 
                    "You didn't complete your payment.", 
                    "warning"
                  );
              },
              callback: function() {
                if (!_this.order_load) {
                  _this.order_text = "";
                  _this.order_load = true;
                  _this.axios
                    .post(`${BASE_URL}adduserorder`, {
                        userId: _this.auth_user_data.id,
                        userEmail: _this.auth_user_data.email,
                        phone: _this.auth_user_data.phone,
                        deliveryFee: _this.delivery_fee,
                        foodFee: _this.total_amount,
                        payment_status: _this.payment,
                        orderfood: _this.order_food,
                        deliveryAddress: _this.current_location,
                    })
                    .then(() => {
                        _this.$swal.fire(
                          "Order Received", 
                          "Your order has been sent. You'll be contacted soon.", 
                          "success").then(() => {
                            _this.$router.push({ name: "Home" });
                        });
                    })
                    .catch(() => {
                        _this.$swal.fire(
                          "Oops", 
                          "There is a problem with your order. Check and try again.", 
                          "error"
                        );
                    })
                    .then(() => {
                        _this.order_text = "Order Now";
                        _this.order_load = false;
                    });
                }
              }
            });
            handler.openIframe();
          }
        } else {
          _this.$refs.login_button.click();
        }
    },
    remove_from_cart(item) {
        let _this = this;
        _this.cart.splice(_this.cart.indexOf(item), 1);
    },
    add_to_cart() {

      let _this = this, is_added = false;

      _this.cart.forEach(cart_item => {
        if (cart_item.id == _this.current_menu.id) {
          let temp_cart_item = {...cart_item};
          temp_cart_item.number_to_order = _this.quantity; 
          _this.$set(_this.cart, _this.cart.indexOf(cart_item), temp_cart_item);
          is_added = true;
        }
      })

      if (!is_added) {
        let the_menu = _this.current_menu;
        the_menu.number_to_order = _this.quantity;
        _this.cart.push(the_menu);
      }

      _this.$refs.close_add_to_cart_modal.click();

      

      // let _this = this, a = false;
      // if (
      //     (_this.cart.forEach(cart_item => {
      //         if (cart_item.id == _this.current_menu.id) {
      //             var s = Object(l["a"])({}, cart_item);
      //             s.number_to_order += 1;
      //             _this.$set(_this.cart, _this.cart.indexOf(cart_item), s)
      //             a = true;
      //         }
      //     }),
      //     0 == a)
      // ) {
      //     let the_menu = _this.current_menu;
      //     the_menu.number_to_order = _this.quantity;
      //     _this.cart.push(the_menu);
      // }
      // _this.$refs.close_add_to_cart_modal.click();
      // _this.quantity = 1;
    },
    get_occurrence(t, a) {
        var e = 0;
        return (
            t.forEach(function (t) {
                return t === a && e++;
            }),
            e
        );
    },
    register() {
        let _this = this;
        if(!_this.reg_load) {
          _this.reg_text = "";
          _this.reg_load = true;
          _this.axios
            .post(`${BASE_URL}webregister`, {
                name: _this.reg_form_data.name,
                phone: _this.reg_form_data.phone,
                email: _this.reg_form_data.email,
                password: _this.reg_form_data.password,
                confirm_pass: _this.reg_form_data.confirm_pass,
            })
            .then(response => {
              _this.$refs.close_auth.click(),
              _this.store_login(response.data),
              _this.$swal.fire(
                "Great", 
                "Registration successful. Please proceed to login.", 
                "success"
                ).then(() => {
                  _this.view = "login";
              });
              _this.reg_form_data.email = "";
              _this.reg_form_data.password = "";
              _this.reg_form_data.confirm_pass = "";
              _this.reg_form_data.phone = "";
              _this.reg_form_data.name = "";
            })
            .catch(() => {
              _this.$swal.fire(
                "Error", 
                "Please enter correct details.", 
                "error"
              );
            })
            .then(() => {
              _this.reg_text = "Sign Up";
              _this.reg_load = false;
            })
        }
    },
    login () {
        let _this = this;
        if(!_this.login_load) {
          _this.login_text = "";
          _this.login_load = true;
          _this.axios
            .post(`${BASE_URL}weblogin`, { 
              email: _this.form_data.email, 
              password: _this.form_data.password 
            })
            .then(response => {
                _this.$refs.close_auth.click();
                _this.store_login(response.data);
                _this.form_data.email = "";
                _this.form_data.password = "";
                _this.$swal.fire({ 
                  title: "Great", 
                  text: "Login successful.", 
                  icon: "success", 
                  confirmButtonText: "OK" 
                }).then(() => {
                    _this.check_user();
                });
            })
            .catch(() => {
                _this.$swal.fire(
                  "Error", 
                  "Please enter correct login details.", 
                  "error"
                );
            })
            .then(() => {
                _this.login_text = "Login Now!";
                _this.login_load = false;
            })
        }
    },
  },
  mounted() {
      let _this = this;
      document.title = _this.restaurant.restaurant_name + " - Chops";
      const DS = new window.google.maps.DirectionsService();
      setTimeout(() => {
          DS.route({ 
            origin: _this.latitude + "," + _this.longitude, 
            destination: _this.restaurant.latitude + "," + _this.restaurant.longitude, 
            travelMode: "DRIVING" 
          }, (details, status) => {
              if (status == "OK") {
                _this.route_details = details;
              }
          });
      }, 0);
      _this.fetch_all_menu();
  }
}
</script>

<style scoped>
/* .mobile_fixed {
  display: block !important;
} */
</style>
