<template>
  <header class="header_in clearfix">
      <i 
      class="fad fa-spinner" 
      style="opacity: 0; font-size: 0.1%; position: absolute;">
      </i>
      <div class="container">
          <div id="logo">
              <router-link :to="{ name: 'Home' }">
                  <img class="logo_normal" src="/assets/img/logo_sticky.png" width="70" height="41" alt="">
              </router-link>
          </div>
          <div class="layer"></div><!-- Opacity Mask Menu Mobile -->
          <ul id="top_menu" :style="logged_in ? 'top: 6px !important' : null">
              <li v-if="logged_in">
                <div class="dropdown user clearfix">
                  <a 
                  id="sign-in" 
                  class="user_logged" 
                  style="cursor: pointer;"
                  role="button" 
                  data-toggle="dropdown" 
                  aria-haspopup="true" 
                  aria-expanded="false">
                    <figure>
                      <img src="/assets/img/avatar.jpg" alt="" />
                    </figure>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" href="#">Orders</a>
                      <a class="dropdown-item" href="#">User Profile</a>
                      <a class="dropdown-item" href="#" @click="logout_request()">Logout</a>
                    </div>
                </div>
                
              </li>
              <!-- <li 
              v-if="!logged_in"
              class="d-none d-md-block">
                  <div class="dropdown">
                    <i
                    class="dropdown-toggle fal fa-camera" 
                    href="#" 
                    role="button" 
                    id="dropdownMenuLink" 
                    data-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false">
                    </i>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" href="#">Orders</a>
                      <a class="dropdown-item" href="#">User Profile</a>
                      <a class="dropdown-item" href="#">Logout</a>
                    </div>
                  </div>
              </li> -->
              <li v-else>
                <a 
                ref="login_icon"
                style="cursor: pointer;"
                id="sign-in" 
                class="login"
                data-toggle="modal" 
                data-target="#login">
                    Sign In
                </a>
                </li>
              <!-- <li>
                  <a 
                    style="cursor: pointer;"
                    @click="current_menu = menu" 
                    class="menu_item modal_dialog" 
                    data-toggle="modal" 
                    data-target="#login">
                    Sign In
                </a>
              </li> -->
          </ul>
          <!-- /top_menu -->
          <a href="#" class="open_close">
              <i class="icon_menu"></i><span>Menu</span>
          </a>
          <nav class="main-menu mt-0">
              <div id="header_menu">
                    <a href="#" class="open_close">
                        <i class="icon_close"></i><span>Menu</span>
                    </a>
                    <router-link :to="{ name: 'Home' }">
                        <img src="/assets/img/logo_sticky.png" width="70" height="41" alt="">
                    </router-link>
              </div>
              <ul>
                  <li>
                    <a href="https://vendor.chops.ng/register" target="_blank">
                      Partner with us
                    </a>
                  </li>
                  <li class="d-md-none"><a href="#">{{ current_location || 'Please allow your browser detect your location' }}</a></li>
                  <li class="d-md-none"><a href="#" @click.prevent="get_location2()">Detect current location</a></li>
              </ul>
          </nav>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="login" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div v-if="view == 'login'" class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Login</h5>
              <button @click="view == 'forgotpassword' ? view = 'login' : null" ref="close_auth" style="outline: none !important;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div 
            class="modal-body" 
            style="border-bottom: 1px solid #dee2e6;">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input v-model="form_data.email" type="text" placeholder="Enter email" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Password</label>
                    <input v-model="form_data.password" type="password" placeholder="Enter password" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <small class="float-left">
                    <a @click="view = 'register'" class="auth d-none d-md-block" style="cursor: pointer;">
                      Don't have an account? Register
                    </a>
                    <a @click="view = 'register'" class="auth d-block d-md-none" style="cursor: pointer;">
                      Tap to Register
                    </a>
                  </small>
                  <small class="float-right">
                    <a @click="view = 'forgotpassword'" class="auth" style="cursor: pointer;">
                      Forgot password
                  </a>
                  </small>
                </div>
              </div>
            </div>
            <div class="modal-footer text-center row" style="display: block; border-top: none;">
              <button 
              type="button" 
              data-dismiss="modal" 
              class="btn btn-secondary col-md-4" 
              style="width: 30%; outline: none !important;">
                Cancel
              </button>
              <button @click.prevent="login()" 
              class="btn_1 gradient mb_5"
              style="width: 60%;">
                  <span>
                      {{ login_text }}
                      <i class="fad fa-spinner fa-spin" :style="!login_load ? 'display: none;' : ''"></i>
                  </span>
              </button>
            </div>
          </div>
          <div v-else-if="view == 'register'" class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Register</h5>
              <button ref="close_auth" style="outline: none !important;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div 
            class="modal-body" 
            style="border-bottom: 1px solid #dee2e6;">
               <div class="row">
                 <div class="col-md-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input v-model="reg_form_data.name" type="text" placeholder="Enter your name" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Phone</label>
                    <input v-model="reg_form_data.phone" type="text" placeholder="Enter your phone number" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Email</label>
                    <input v-model="reg_form_data.email" type="text" placeholder="Enter email" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Password</label>
                    <input v-model="reg_form_data.password" type="password" placeholder="Enter password" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Confirm Password</label>
                    <input v-model="reg_form_data.confirm_pass" type="password" placeholder="Confirm password" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12 text-right">
                  <small>
                    <a @click="view = 'login'" class="auth" style="cursor: pointer;">
                      Already have an account? Login
                  </a>
                  </small>
                </div>
              </div>
            </div>
            <div class="modal-footer text-center row" style="display: block; border-top: none;">
              <button 
              type="button" 
              data-dismiss="modal" 
              class="btn btn-secondary col-md-4" 
              style="width: 30%; outline: none !important;">
                Cancel
              </button>
              <button @click.prevent="register()" 
              class="btn_1 gradient mb_5"
              style="width: 60%;">
                  <span>
                      {{ reg_text }}
                      <i class="fad fa-spinner fa-spin" :style="!reg_load ? 'display: none;' : ''"></i>
                  </span>
              </button>
            </div>
          </div>
        </div>
      </div>
  </header>
  <!-- /header -->
</template>

<script>
import { BASE_URL } from '@/main';
import { mapActions, mapGetters } from 'vuex';
export default {
    data() {
      return {
        form_data: { 
          email: "", 
          password: "" 
        },
        reg_form_data: { 
          name: "", 
          email: "", 
          phone: "", 
          password: "", 
          confirm_pass: "" 
        },
        login_text: "Login Now!",
        login_load: false,
        reg_text: "Sign Up",
        reg_load: false,
        view: "login",
      }
    },
    computed: {
        ...mapGetters([
            "current_location",
            "logged_in",
            "auth_user_data",
            "user_location_granted"
        ])
    },
    methods: {
        ...mapActions([
            "logout",
            "store_login",
            "get_location"
        ]),
        logout_request() {
          let _this = this;
          _this.logout().then(() => {
            _this.$swal.fire(
              "Great", 
              "Logout successful.", 
              "success"
            )
          })
        },
        get_location2 () {
            let _this = this;
            if (!_this.user_location_granted) {
                _this.$swal.fire(
                    "😟", 
                    "Geolocation is not enabled. Please enable to use this feature.", 
                    "warning"
                    ).then(() => {
                        _this.get_location();
                    })
            }
            _this.get_location();
        },
        register() {
            let _this = this;
            if(_this.reg_form_data.password != _this.reg_form_data.confirm_pass) {
              _this.$refs.close_auth.click();
              _this.$swal.fire(
                "Errr...", 
                "Password and password confirmation don't match.", 
                "error"
              );
              return;
            }
            if(_this.reg_form_data.password.length < 8) {
              _this.$refs.close_auth.click();
              _this.$swal.fire(
                "Errr...", 
                "Your password is less than 8 characters long.", 
                "error"
              );
              return;
            }
            if(!_this.reg_load) {
              _this.reg_text = "";
              _this.reg_load = true;
              _this.axios
                .post(`${BASE_URL}webregister`, {
                    name: _this.reg_form_data.name,
                    phone: _this.reg_form_data.phone,
                    email: _this.reg_form_data.email,
                    password: _this.reg_form_data.password,
                    confirm_pass: _this.reg_form_data.confirm_pass,
                })
                .then(response => {
                  _this.$refs.close_auth.click();
                  _this.store_login(response.data);
                  _this.$swal.fire(
                    "Great", 
                    "Registration successful. Please proceed to login.", 
                    "success"
                    ).then(() => {
                      _this.view = "login";
                      _this.$refs.login_icon.click();
                  });
                  _this.reg_form_data.email = "";
                  _this.reg_form_data.password = "";
                  _this.reg_form_data.confirm_pass = "";
                  _this.reg_form_data.phone = "";
                  _this.reg_form_data.name = "";
                })
                .catch(() => {
                  _this.$refs.close_auth.click();
                  _this.$swal.fire(
                    "Error", 
                    "Please enter correct details.", 
                    "error"
                  );
                })
                .then(() => {
                  _this.reg_text = "Sign Up";
                  _this.reg_load = false;
                })
            }
        },
        login() {
            let _this = this;
            if(!_this.login_load) {
              _this.login_text = "";
              _this.login_load = true;
              _this.axios
                .post(`${BASE_URL}weblogin`, { 
                  email: _this.form_data.email, 
                  password: _this.form_data.password 
                })
                .then(response => {
                    _this.$refs.close_auth.click();
                    _this.store_login(response.data);
                    _this.form_data.email = "";
                    _this.form_data.password = "";
                    _this.$swal.fire({ 
                      title: "Great", 
                      text: "Login successful.", 
                      icon: "success", 
                      confirmButtonText: "OK" 
                    });
                })
                .catch(() => {
                    _this.$refs.close_auth.click();
                    _this.$swal.fire(
                      "Error", 
                      "Please enter correct login details.", 
                      "error"
                    );
                })
                .then(() => {
                    _this.login_text = "Login Now!";
                    _this.login_load = false;
                })
            }
        }
    }
}
</script>

<style>

</style>