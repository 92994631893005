<template>
  <div 
  :style="$route.name == 'Restaurant' ? 'margin-bottom: 2.7rem !important;' : null" 
  @click="scroll_to_top()" id="toTop"></div><!-- Back to top button -->
</template>

<script>
export default {
    methods: {
        scroll_to_top() {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style>

</style>