import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import swalPlugin from "./plugins/VueSweetalert2";

Vue.config.productionTip = false

Vue.filter("number_with_commas", function (t) {
  return t.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

Vue.use(VueAxios, axios)

Vue.use(swalPlugin);

export const BASE_URL = "https://chops.ijeapp.com/api/";
export const ASSETS_URL = "https://chops.ijeapp.com";
export const MAP_API_KEY = "AIzaSyCyygbTzlJEo0UWG64Bux_MZk-FDDUW8Dw";
export const PAYSTACK_KEY = "pk_live_c4031fd5b002e8384b14f1ba241b608db626e0e6";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
